import { ChevronUpIcon, PencilIcon } from "@heroicons/react/24/solid";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FIRMFLOW_NAMES } from "../../helpers/firmFlowNames";
import { intervalTypeMap } from "../../helpers/intervalTypesMap";
import { createClientServiceSubscriptionTask } from "../../services/clientServiceSubscriptionTask/createClientServiceSubscriptionTask";
import { getClientServiceSubscriptionTasksByClientServiceId } from "../../services/clientServiceSubscriptionTask/getClientServiceSubscriptionTasksByServiceId";
import { updateTaskService } from "../../services/clientServiceSubscriptionTask/updateTaskService";
import { getClientServiceSubscriptionTaskChecklistItemByTaskId } from "../../services/clientServiceSubscriptionTaskChecklistItem/getClientServiceSubscriptionTaskChecklistItemByTaskId";
import { updateClientServiceSubscriptionTaskChecklistItemCheck } from "../../services/clientServiceSubscriptionTaskChecklistItem/updateClientServiceSubscriptionTaskChecklistItemCheck";
import { getEmployees } from "../../services/getEmployees";
import { getLoggedInEmployee } from "../../services/getLoggedInEmployee";
import { renewSubscriptionTask } from "../../services/renewSubscriptionTask";
import AddNewClientServiceSubscriptionTask from "./addNewClientSubscriptionTaskModal/AddNewClientSubscriptionTask";
import ClientServiceSubscriptionTaskChecklistItemTableBody from "./checklistItems/ClientServiceSubscriptionTaskChecklistItemTableBody";
import RenewServiceAutomaticallyModal from "./RenewServiceAutomaticallyModal";
import AssignNewEmployeeToJobModal from "./updateClientServiceSubscriptionTaskModal/AssignNewEmployeeToJobModal";
import ServiceStatusesListBox from "./updateClientServiceSubscriptionTaskModal/ServiceStatusesListBox";
import UpdateClientServiceSubscriptionTaskModal from "./updateClientServiceSubscriptionTaskModal/UpdateClientServiceSubscriptionTaskModal";
import { renewClientServiceSubscription } from "../../services/clientService/renewClientServiceSubscription";
import { getBgColorFromDate } from "../../helpers/getBgColorFromDate";
import StartJobModal from "./updateClientServiceSubscriptionTaskModal/StartJobModal";
import { formatDate } from "../../helpers/formatDate";

const STATUSES = ["To Do", "In Progress", "Complete"];

const STATUSES_IN_FILTER = [
    { id: -1, state: "All" },
    { id: 0, state: "To Do" },
    { id: 1, state: "In Progress" },
];

const RENEWAL_STATE = ["None", "Renew", "Renewed"];

const ClientServiceSubscriptionTasksList = ({
    clientServiceId,
    onHandleUpdateClientServiceStatusComplete,
    subscriptionInterval,
    isServiceStatusInProgress,
    clientService,
    updateClientService,
}) => {
    const [tasks, setTasks] = useState([]);
    const [incompleteTasks, setIncompleteTasks] = useState([]);
    const [completedTasks, setCompletedTasks] = useState([]);
    const [updateTaskModal, setUpdateTaskModal] = useState({});
    const [isUpdateTaskModalOpen, setIsUpdateTaskModalOpen] = useState(false);
    const [isAddNewTaskModalOpen, setIsAddNewTaskModalOpen] = useState(false);
    const [loggedInEmployee, setLoggedInEmployee] = useState({});
    const [selectedFilterStatus, setSelectedFilterStatus] = useState({});
    const [assignNextJobModalOpen, setAssignNextJobModalOpen] = useState(false);
    const [nextJobToAssign, setNextJobToAssign] = useState({});
    const [checklistItemToUpdate, setChecklistItemToUpdate] = useState({});
    const [checklistItems, setChecklistItems] = useState([]);
    const [currentJob, setCurrentJob] = useState([]);
    const [assignRenewedServiceTo, setAssignRenewedServiceTo] = useState(null);
    const [assignedRenewServiceDueDate, setAssignedRenewServiceDueDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [assignRenewedServiceModal, setAssignRenewedServiceModal] =
        useState(false);
    const [employees, setEmployees] = useState([]);
    const [taskToUpdate, setTaskToUpdate] = useState(null);
    const [itemToUpdate, setItemToUpdate] = useState(null);
    const [startJobModal, setStartJobModal] = useState(false);
    const [jobToStart, setJobToStart] = useState(null);

    /*  const latestStatus = () => {
    const lastItem = serviceStatuses.length - 1;
    //Get the status with the highest order
    const lastStatus = serviceStatuses.filter(
      (serviceStatus) =>
        serviceStatus.order == Math.max(serviceStatuses[lastItem].order)
    )[0];
    return lastStatus;
  }; */

    useEffect(() => {
        getNewEmployees();
    }, []);

    const getNewEmployees = async () => {
        const response = await getEmployees();
        setEmployees(response.data.employees);
        console.log(response.data.employees);
    };

    const isTaskValidToRenew = async (clientServiceSubscriptionTaskId) => {
        const checklistItems =
            await getClientServiceSubscriptionTaskChecklistItemByTaskId(
                clientServiceSubscriptionTaskId
            );
        const numberOfUncheckedItems =
            checklistItems.data.checklistItemsResponse.filter(
                (item) => item.isChecked === false
            ).length;
        if (numberOfUncheckedItems > 0) {
            return false;
        }
        return true;
    };

    const calculateNewRenewalDate = (date) => {
        console.log("subinterval", subscriptionInterval);
        const regex = new RegExp(`^${subscriptionInterval}$`);
        const daysToAdd = intervalTypeMap.filter((interval) =>
            regex.test(interval.name)
        )[0].days;
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate;
    };

    async function renewTask(subscriptionId, taskId, assignedDate) {
        const isChecklistItemsChecked = await isTaskValidToRenew(taskId);
        if (!isChecklistItemsChecked) {
            toast.error("Check all the checklist items before renewing.");
            return;
        }
        let payload = {
            subscriptionId: subscriptionId,
            taskId: taskId,
            assignedDate: calculateNewRenewalDate(assignedDate),
            dueDate: calculateNewRenewalDate(assignedDate),
        };
        console.log(payload.dueDate.getFullYear());
        console.log(clientService.year);
        if (payload.dueDate.getFullYear() > clientService.year) {
            toast.error(
                "Renewal was not possible since it exceeds the service year"
            );
            getNewTasks();
            return;
        }
        const res = await renewSubscriptionTask(payload);
        if (res.success) {
            getNewTasks();
        } else {
            alert(res.message);
        }
    }

    const updateTasks = (updatedTask) => {
        /*  const newTasks = tasks.map((task) => {
      if (task.id === updatedTask.id) {
        return updatedTask;
      } else {
        return task;
      }
    });
    console.log("newTasks", newTasks);
    setTasks(newTasks); */
        getNewTasks();
        setIsUpdateTaskModalOpen(false);
    };

    /* const onUpdateAllChecklistItems = async (task) => {
    const completeStatus = latestStatus();
    console.log(task.serviceStatus);

    if (
      task.serviceStatus == null ||
      completeStatus.id == task.serviceStatus.id
    ) {
      return;
    }
    task.serviceStatus = completeStatus;
    task.completedBy = loggedInEmployee;
    task.completedDate = moment().format("YYYY-MM-DD");
    const updatedTask = await updateTaskService(task.id, task);
    if (updatedTask.data.success) {
      toast.success(updatedTask.data.message);
      updateTasks(updateTaskModal);
    } else {
      toast.error(updatedTask.data.message);
    }
  }; */
    const IsEveryJobComplete = (taskId) => {
        console.log("test tasks if complete", tasks);
        let result = true;
        tasks
            .filter((task) => task.id !== taskId)
            .map((task) => {
                if (task.status !== 2) {
                    result = false;
                    return result;
                }
            });
        return result;
    };

    const updateChecklistItemAndJob = async (
        nextJob = nextJobToAssign,
        actualJob = currentJob,
        checklistItem = checklistItemToUpdate
    ) => {
        const updateNextJobResponse = await updateTaskService(nextJob.id, nextJob);
        if (updateNextJobResponse.data.success) {
            const updateChecklistItemResponse =
                await updateClientServiceSubscriptionTaskChecklistItemCheck(
                    checklistItem.id,
                    true
                );
            if (updateChecklistItemResponse.success) {
                const updateCurrentJobResponse = await updateTaskService(
                    actualJob.id,
                    actualJob
                );
                if (updateCurrentJobResponse.data.success) {
                    toast.success("Jobs updated successfully!");
                    getNewTasks();
                } else {
                    toast.error(updateCurrentJobResponse.data.message);
                }
            } else {
                toast.error(updateChecklistItemResponse.data.message);
            }
        } else {
            toast.error(updateNextJobResponse.data.message);
        }
        initializeJobs();
        setAssignNextJobModalOpen(false);
    };

    const initializeJobs = () => {
        setCurrentJob({});
        setNextJobToAssign({});
    };

    const onUpdateAllChecklistItemsV2 = async (task, item) => {
        console.log("tasks", tasks);
        console.log("task", task);
        setTaskToUpdate(task);
        setItemToUpdate(item);

        if (IsEveryJobComplete(task.id) && clientService.isEveryItemRenewable) {
            setAssignRenewedServiceModal(true);
            return;
        }

        //find next job that is in To Do
        let nextJob = tasks.find((t) => t.order > task.order && t.status !== 2);

        //if there is no next job, find the previous job that is not completed
        if (!nextJob) {
            nextJob = tasks.find((t) => t.order < task.order && t.status !== 2);
        }

        console.log("nextJob", nextJob);
        const currentJobAssigned = {
            ...task,
            status: 2,
            completedBy: loggedInEmployee,
            completedDate: moment().format("YYYY-MM-DD"),
        };
        if (nextJob) {
            const nextJobToAssign = {
                ...nextJob,
                assignedByEmployee: loggedInEmployee,
                status: 1,
                dueDate: new Date().toISOString().split("T")[0],
            };
            setNextJobToAssign(nextJobToAssign);
            setCurrentJob(currentJobAssigned);
            setChecklistItemToUpdate(item);

            if (nextJob.assignedEmployees.length < 1) {
                setAssignNextJobModalOpen(true);
            } else {
                await updateChecklistItemAndJob(
                    nextJobToAssign,
                    currentJobAssigned,
                    item
                );
                initializeJobs();
            }
        } else {
            initializeJobs();
            await updateJobStatus(currentJobAssigned);
            //the checklist item will get updated after the job, since update job status affects it
            await updateChecklistItem(item);
            await onHandleUpdateClientServiceStatusComplete();
        }
    };

    const AddNewTask = async (newTask) => {
        const response = await createClientServiceSubscriptionTask(
            clientServiceId,
            newTask
        );
        console.log(clientServiceId);
        if (response.data.success) {
            toast.success(response.data.message);
            getNewTasks();
            setIsAddNewTaskModalOpen(false);
        } else {
            toast.error(response.data.message);
        }
    };

    /**
     * This function compares two objects of employee since the one coming from the tasks is different from the one
     * coming from the employees api.
     * @param {*} employee
     */
    const validateEmployee = (employee) => {
        if (employee) {
            if (employee.fullName) {
                return employee.fullName;
            } else if (employee.firstName && employee.lastName) {
                return employee.firstName + " " + employee.lastName;
            } else {
                return "";
            }
        }
    };

    /* const validateAllJobsComplete = (jobs, latestServiceStatusOrder) => {
    if (clientServiceStatus == "Complete") {
      return;
    }
    const incompleteJobs = jobs.filter(
      (job) =>
        job.serviceStatus &&
        job.serviceStatus.order !== latestServiceStatusOrder
    );
    if (incompleteJobs.length == 0 && jobs.length > 0) {
      onHandleUpdateClientServiceStatusComplete();
    }
  }; */

    const getNewLoggedInEmployee = async () => {
        const currentEmployee = await getLoggedInEmployee();
        if (currentEmployee.success) {
            setLoggedInEmployee(currentEmployee.data.employeeResponse);
            console.log(currentEmployee);
        }
    };

    const getNewTasks = async () => {
        const { data: newTasks } =
            await getClientServiceSubscriptionTasksByClientServiceId(
                clientServiceId
            );
        setTasks(newTasks.tasksResponse);
        console.log("jobs response", newTasks.tasksResponse);

        const completedJobs = newTasks.tasksResponse.filter(
            (task) => task.status === 2
        );
        setCompletedTasks(completedJobs);

        const incompleteJobs = newTasks.tasksResponse.filter(
            (task) => task.status !== 2
        );
        setIncompleteTasks(incompleteJobs);
        if (incompleteJobs.length > 0) {
            incompleteJobs[0].isOpen = true;
        }

        setSelectedFilterStatus(STATUSES_IN_FILTER[0]);
    };

    /* const onHandleFilterStatus = (currentStatus) => {
    setSelectedFilterStatus(currentStatus);
    if (currentStatus.id !== "all") {
      const newIncompleteJobs = tasks.filter(
        (task) =>
          task.serviceStatus && task.serviceStatus.order === currentStatus.order
      );
      setIncompleteTasks(newIncompleteJobs);
    } else {
      const newIncompleteJobs = tasks.filter(
        (task) =>
          (task.serviceStatus &&
            task.serviceStatus.order !==
              serviceStatuses[serviceStatuses.length - 1].order) ||
          task.serviceStatus == null
      );
      setIncompleteTasks(newIncompleteJobs);
    }
  }; */

    const onHandleFilterStatusV2 = (currentStatus) => {
        setSelectedFilterStatus(currentStatus);
        let newIncompleteJobs = "";
        if (currentStatus.id !== -1) {
            newIncompleteJobs = tasks.filter(
                (task) => task.status == currentStatus.id
            );
        } else {
            newIncompleteJobs = tasks.filter((task) => task.status !== 2);
        }
        setIncompleteTasks(newIncompleteJobs);
    };

    const updateJobStatus = async (job) => {
        const response = await updateTaskService(
            job.id,
            job,
            loggedInEmployee.employeeId
        );
        if (response.data.success) {
            toast.success(response.data.message);
            updateTasks(job);
        } else {
            toast.error(response.data.message);
        }
    };

    const updateChecklistItem = async (item) => {
        const response = await updateClientServiceSubscriptionTaskChecklistItemCheck(
            item.id,
            true
        );
        if (response.success == true) {
            updateTasks();
            toast.success("Checklist item updated!");
        } else {
            toast.error("Error while updating checklist item.");
        }
    };

    const onClickRenewalService = async (e) => {
        e.preventDefault();
        if (assignedRenewServiceDueDate == null) {
            toast.error("You need to select a due date");
            return;
        }
        if (assignRenewedServiceTo !== null) {
            const currentJobAssigned = {
                ...taskToUpdate,
                status: 2,
                completedBy: loggedInEmployee,
                completedDate: moment().format("YYYY-MM-DD"),
            };
            initializeJobs();
            updateChecklistItem(itemToUpdate);
            updateJobStatus(currentJobAssigned);
            await onHandleUpdateClientServiceStatusComplete();
            await renewClientServiceSubscription(
                assignRenewedServiceTo,
                assignedRenewServiceDueDate,
                clientServiceId
            );
            setAssignRenewedServiceModal(false);
        } else {
            toast.error("You need to select an employee");
        }
    };

    const formatUpdatedDate = (dateToFormat) => {
        const newDate = new Date(dateToFormat).toLocaleDateString();
        const newTime = new Date(dateToFormat).toLocaleTimeString();
        return newDate + " " + newTime;
    };

    const onStartJob = async (job) => {
        console.log(job);
        setJobToStart(job);
        setStartJobModal(true);
    };

    useEffect(() => {
        getNewLoggedInEmployee();
        getNewTasks();
    }, [isServiceStatusInProgress]);

    return (
        <div className="flex flex-col space-y-8 w-full">
            <section>
                <AddNewClientServiceSubscriptionTask
                    open={isAddNewTaskModalOpen}
                    setOpen={setIsAddNewTaskModalOpen}
                    AddNewTask={AddNewTask}
                    clientServiceId={clientServiceId}
                />
                <UpdateClientServiceSubscriptionTaskModal
                    updateTaskModal={updateTaskModal}
                    setUpdateTaskModal={setUpdateTaskModal}
                    open={isUpdateTaskModalOpen}
                    setOpen={setIsUpdateTaskModalOpen}
                    loggedInEmployee={loggedInEmployee}
                    updateTasks={updateTasks}
                    clientServiceId={clientServiceId}
                />
                <AssignNewEmployeeToJobModal
                    currentJob={currentJob}
                    setCurrentJob={setCurrentJob}
                    nextJob={nextJobToAssign}
                    setNextjob={setNextJobToAssign}
                    open={assignNextJobModalOpen}
                    setOpen={setAssignNextJobModalOpen}
                    getNewTasks={getNewTasks}
                    checklistItemToUpdate={checklistItemToUpdate}
                    clientService={clientService}
                    renewTask={renewTask}
                    loggedInEmployee={loggedInEmployee}
                    updateChecklistItemAndJob={updateChecklistItemAndJob}
                    initializeJobs={initializeJobs}
                />
                <RenewServiceAutomaticallyModal
                    title="Renew Service for"
                    isModalOpen={assignRenewedServiceModal}
                    setIsModalOpen={setAssignRenewedServiceModal}
                    onHandleCancel={() => setAssignRenewedServiceModal(false)}
                    onHandleSubmit={onClickRenewalService}
                    employee={assignRenewedServiceTo}
                    setEmployee={setAssignRenewedServiceTo}
                    dueDate={assignedRenewServiceDueDate}
                    setDueDate={setAssignedRenewServiceDueDate}
                    employees={employees}
                    clientService={clientService}
                />
                <StartJobModal
                    open={startJobModal}
                    setOpen={setStartJobModal}
                    nextJob={jobToStart}
                    setNextjob={setJobToStart}
                    getNewJobs={getNewTasks}
                    initializeJobs={initializeJobs}
                    updateChecklistItemAndJob={updateChecklistItemAndJob}
                />
            </section>

            <div className="sm:flex sm:justify-between sm:items-center mb-4 w-full mt-12">
                <div className="flex items-center justify-between w-full mb-4 sm:mb-0">
                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                        {FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASKS}
                    </h1>
                    {/*  {isServiceStatusInProgress && (
            <button
              onClick={() => setIsAddNewTaskModalOpen(true)}
              className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
              disabled
            >
              <PlusIcon className="w-4 h-4" />
              <span className="xs:block ml-2">
                Add Custom {FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK}
              </span>
            </button>
          )} */}
                </div>
            </div>

            <h2 className="text-yellow-500 text-lg font-bold">Incomplete Jobs</h2>
            <div className="sm:w-1/2 lg:w-1/4">
                <ServiceStatusesListBox
                    label="Filter by Status"
                    selectedServiceStatus={selectedFilterStatus}
                    serviceStatuses={STATUSES_IN_FILTER}
                    onChange={onHandleFilterStatusV2}
                />
            </div>
            {incompleteTasks &&
                incompleteTasks.map((task, index) => {
                    return (
                        <div
                            key={task + index}
                            className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full"
                        >
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{
                                    type: "linear",
                                    height: { duration: 0.2 },
                                }}
                                className={"overflow-x-auto overflow-y-hidden"}
                            >
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="first:pl-5 py-3 whitespace-nowrap">
                                                <div className="flex items-center space-x-3">
                                                    <ChevronUpIcon
                                                        className={`h-4 w-4 ${
                                                            task.isOpen &&
                                                            "rotate-180"
                                                        }`}
                                                        onClick={() => {
                                                            const newTasks = [
                                                                ...incompleteTasks,
                                                            ];
                                                            newTasks[index].isOpen =
                                                                !newTasks[index]
                                                                    .isOpen;
                                                            setIncompleteTasks(
                                                                newTasks
                                                            );
                                                            console.log(
                                                                incompleteTasks[
                                                                    index
                                                                ]
                                                            );
                                                        }}
                                                    />
                                                    <h2 className="font-semibold text-slate-800">
                                                        {task.name}
                                                    </h2>
                                                    {/* <span className="inline-flex items-center rounded-full bg-yellow-500 px-2.5 py-0.5 text-xs font-medium text-gray-800">
                            {task.serviceStatus !== null &&
                              task.serviceStatus.state}
                          </span> */}
                                                    <span
                                                        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-white ${
                                                            task.status === 0
                                                                ? "bg-red-500"
                                                                : "bg-yellow-500"
                                                        }`}
                                                    >
                                                        {task.status !== null &&
                                                            STATUSES[task.status]}
                                                    </span>
                                                    {RENEWAL_STATE[
                                                        task.taskRenewalState
                                                    ] === "Renew" &&
                                                        isServiceStatusInProgress && (
                                                            <button
                                                                onClick={() => {
                                                                    renewTask(
                                                                        clientServiceId,
                                                                        task.id,
                                                                        task.assignedDate
                                                                    );
                                                                }}
                                                                className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                            >
                                                                {
                                                                    RENEWAL_STATE[
                                                                        task
                                                                            .taskRenewalState
                                                                    ]
                                                                }
                                                            </button>
                                                        )}
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap min-w-full">
                                                <label className="block font-medium text-sm">
                                                    Notes:{" "}
                                                </label>
                                                {task.notes !== null &&
                                                task.notes !== "" ? (
                                                    <textarea
                                                        value={task.notes}
                                                        className="font-medium text-sm shadow-lg rounded-sm border border-slate-200 whitespace-pre-line break-words w-full min-w-max resize"
                                                        rows={5}
                                                        cols={50}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <p className="font-semibold text-slate-800"></p>
                                                )}
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div className="text-left font-medium text-sm">
                                                    Assigned{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.assignedDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.assignedDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : "Not assigned"}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Completed{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.completedDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.completedDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : ""}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Due{" "}
                                                    <span
                                                        className="inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white mb-2"
                                                        style={{
                                                            background: `${
                                                                task.dueDate !==
                                                                "0001-01-01T00:00:00"
                                                                    ? getBgColorFromDate(
                                                                          task.dueDate
                                                                      )
                                                                    : "gray"
                                                            }`,
                                                        }}
                                                    >
                                                        {task.dueDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.dueDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : "Not assigned"}
                                                    </span>
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div className="text-left font-medium text-sm">
                                                    Assigned to{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.assignedEmployees &&
                                                            task.assignedEmployees
                                                                .map((employee) =>
                                                                    employee.fullName
                                                                        ? employee.fullName
                                                                        : employee.firstName +
                                                                          " " +
                                                                          employee.lastName
                                                                )
                                                                .join(", ")}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Assigned by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.assignedByEmployee
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Last modified by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.lastModifiedBy
                                                        )}
                                                    </span>
                                                </div>

                                                <div className="text-left font-medium text-sm">
                                                    Last modified at{" "}
                                                    {task.lastModifiedBy !==
                                                        null && (
                                                        <span className="font-medium text-sky-500">
                                                            {formatUpdatedDate(
                                                                task.updated
                                                            )}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="text-left font-medium text-sm">
                                                    Completed by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.completedBy
                                                        )}
                                                    </span>
                                                </div>
                                            </th>
                                            {task.status === 1 && (
                                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center justify-end">
                                                        <button
                                                            onClick={() => {
                                                                console.log(task);
                                                                setUpdateTaskModal(
                                                                    task
                                                                );
                                                                setIsUpdateTaskModalOpen(
                                                                    true
                                                                );
                                                            }}
                                                            className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                        >
                                                            <PencilIcon className="h-4 w-4" />
                                                            <h1 className="text-sm font-medium">
                                                                Update{" "}
                                                                {
                                                                    FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK
                                                                }
                                                            </h1>
                                                        </button>
                                                    </div>
                                                </th>
                                            )}
                                            {isServiceStatusInProgress &&
                                                task.status != 1 && (
                                                    <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                        <div className="flex items-center justify-end">
                                                            <button
                                                                onClick={() =>
                                                                    onStartJob(task)
                                                                }
                                                                className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                            >
                                                                <PencilIcon className="h-4 w-4" />
                                                                <h1 className="text-sm font-medium">
                                                                    Start job
                                                                </h1>
                                                            </button>
                                                        </div>
                                                    </th>
                                                )}
                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <AnimatePresence mode="wait">
                                        {task.isOpen && (
                                            <ClientServiceSubscriptionTaskChecklistItemTableBody
                                                task={task}
                                                onUpdateAllChecklistItems={
                                                    onUpdateAllChecklistItemsV2
                                                }
                                                checklistItems={checklistItems}
                                                setChecklistItems={setChecklistItems}
                                                loggedInEmployee={loggedInEmployee}
                                                isServiceStatusInProgress={
                                                    isServiceStatusInProgress
                                                }
                                                updateClientService={
                                                    updateClientService
                                                }
                                                getNewJobs={getNewTasks}
                                            />
                                        )}
                                    </AnimatePresence>
                                </table>
                            </motion.div>
                        </div>
                    );
                })}

            <h2 className="text-green-500 text-lg font-bold">Complete Jobs</h2>
            {completedTasks &&
                completedTasks.map((task, index) => {
                    return (
                        <div
                            key={task + index}
                            className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full"
                        >
                            <motion.div
                                initial={{ height: 0 }}
                                animate={{ height: "auto" }}
                                exit={{ height: 0 }}
                                transition={{
                                    type: "linear",
                                    height: { duration: 0.2 },
                                }}
                                className={"overflow-x-auto overflow-y-hidden"}
                            >
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="first:pl-5 py-3 whitespace-nowrap">
                                                <div className="flex items-center space-x-3">
                                                    <ChevronUpIcon
                                                        className={`h-4 w-4 ${
                                                            task.isOpen &&
                                                            "rotate-180"
                                                        }`}
                                                        onClick={() => {
                                                            const newTasks = [
                                                                ...completedTasks,
                                                            ];
                                                            newTasks[index].isOpen =
                                                                !newTasks[index]
                                                                    .isOpen;
                                                            setCompletedTasks(
                                                                newTasks
                                                            );
                                                        }}
                                                    />
                                                    <h2 className="font-semibold text-slate-800">
                                                        {task.name}
                                                    </h2>
                                                    {/* <span className="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white">
                            {task.serviceStatus !== null &&
                              task.serviceStatus.state}
                          </span> */}
                                                    <span className="inline-flex items-center rounded-full bg-green-500 px-2.5 py-0.5 text-xs font-medium text-white">
                                                        {task.status !== null &&
                                                            STATUSES[task.status]}
                                                    </span>
                                                    {RENEWAL_STATE[
                                                        task.taskRenewalState
                                                    ] === "Renew" &&
                                                        isServiceStatusInProgress && (
                                                            <button
                                                                onClick={() => {
                                                                    renewTask(
                                                                        clientServiceId,
                                                                        task.id,
                                                                        task.assignedDate
                                                                    );
                                                                }}
                                                                className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                            >
                                                                {
                                                                    RENEWAL_STATE[
                                                                        task
                                                                            .taskRenewalState
                                                                    ]
                                                                }
                                                            </button>
                                                        )}
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <label className="block font-medium text-sm">
                                                    Notes:{" "}
                                                </label>
                                                {task.notes !== null &&
                                                task.notes !== "" ? (
                                                    <textarea
                                                        value={task.notes}
                                                        className="w-full font-medium text-sm shadow-lg rounded-sm border border-slate-200"
                                                        rows={3}
                                                        readOnly
                                                    />
                                                ) : (
                                                    <p className="font-semibold text-slate-800"></p>
                                                )}
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div className="text-left font-medium text-sm">
                                                    Assigned{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.assignedDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.assignedDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : "Not assigned"}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Completed{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.completedDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.completedDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : ""}
                                                    </span>
                                                </div>

                                                <div className="text-left font-medium text-sm">
                                                    Due{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.dueDate !==
                                                        "0001-01-01T00:00:00"
                                                            ? formatDate(
                                                                  task.dueDate,
                                                                  {
                                                                      dateStyle:
                                                                          "short",
                                                                  }
                                                              )
                                                            : "Not assigned"}
                                                    </span>
                                                </div>
                                            </th>
                                            <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                <div className="text-left font-medium text-sm">
                                                    Assigned to{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {task.assignedEmployees &&
                                                            task.assignedEmployees
                                                                .map((employee) =>
                                                                    employee.fullName
                                                                        ? employee.fullName
                                                                        : employee.firstName +
                                                                          " " +
                                                                          employee.lastName
                                                                )
                                                                .join(", ")}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Assigned by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.assignedByEmployee
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="text-left font-medium text-sm">
                                                    Last modified by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.lastModifiedBy
                                                        )}
                                                    </span>
                                                </div>

                                                <div className="text-left font-medium text-sm">
                                                    Last modified at{" "}
                                                    {task.lastModifiedBy !==
                                                        null && (
                                                        <span className="font-medium text-sky-500">
                                                            {formatUpdatedDate(
                                                                task.updated
                                                            )}
                                                        </span>
                                                    )}
                                                </div>

                                                <div className="text-left font-medium text-sm">
                                                    Completed by{" "}
                                                    <span className="font-medium text-sky-500">
                                                        {validateEmployee(
                                                            task.completedBy
                                                        )}
                                                    </span>
                                                </div>
                                            </th>
                                            {task.status === 1 && (
                                                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                                    <div className="flex items-center justify-end">
                                                        {isServiceStatusInProgress && (
                                                            <button
                                                                onClick={() => {
                                                                    console.log(
                                                                        task
                                                                    );
                                                                    setUpdateTaskModal(
                                                                        task
                                                                    );
                                                                    setIsUpdateTaskModalOpen(
                                                                        true
                                                                    );
                                                                }}
                                                                className="flex items-center space-x-1 mr-4 border border-gray-200 px-3 py-2 rounded-md"
                                                            >
                                                                <PencilIcon className="h-4 w-4" />
                                                                <h1 className="text-sm font-medium">
                                                                    Update{" "}
                                                                    {
                                                                        FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTON_TASK
                                                                    }
                                                                </h1>
                                                            </button>
                                                        )}
                                                    </div>
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    {/* Table body */}
                                    <AnimatePresence mode="wait">
                                        {task.isOpen && (
                                            <ClientServiceSubscriptionTaskChecklistItemTableBody
                                                task={task}
                                                onUpdateAllChecklistItems={
                                                    onUpdateAllChecklistItemsV2
                                                }
                                                loggedInEmployee={loggedInEmployee}
                                                checklistItems={checklistItems}
                                                setChecklistItems={setChecklistItems}
                                                getNewJobs={getNewTasks}
                                                updateClientService={
                                                    updateClientService
                                                }
                                            />
                                        )}
                                    </AnimatePresence>
                                </table>
                            </motion.div>
                        </div>
                    );
                })}
        </div>
    );
};

export default ClientServiceSubscriptionTasksList;
