import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";
export const renewClientServiceSubscription = (
    assignTo,
    dueDate,
    clientServiceId
) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.post(
                `${FIRMFLOWURL}api/ClientSubscription/renew/${clientServiceId}`,
                {
                    assignTo: assignTo,
                    dueDate: dueDate,
                },
                {
                    headers: { Authorization: token },
                }
            );
            resolve({ success: true, ...response });
        } catch (err) {
            console.log("renewClientServiceSubscription", err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
