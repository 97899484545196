import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary
import { formatDate } from "../../../../helpers/formatDate";

const STATUSES = ["To DO", "In Progress", "Complete"];

const JobAccountabilityTable = ({ employeeName, onHandleClickNotes, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Department",
                accessor: "department.name",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Client",
                accessor: "clientName",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "clientServiceSubscriptionServiceName",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Job",
                accessor: "name",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },

            {
                Header: "Assigned Date",
                accessor: "assignedDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Due Date",
                accessor: "dueDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Notes",
                accessor: "notes",
                Cell: (props) => (
                    <p
                        onClick={() =>
                            onHandleClickNotes({
                                jobId: props.row.original.id,
                                notes: props.value,
                            })
                        }
                        className="text-gray hover:text-blue-500 rounded-full cursor-pointer"
                    >
                        {props.value ? props.value : "Click to add notes"}
                    </p>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.clientServiceSubscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {STATUSES[props.value]}
                    </a>
                ),
            },
        ],
        [employeeName]
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default JobAccountabilityTable;
