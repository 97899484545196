import { useMemo } from "react";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import NewBadge from "../../components/NewBadge";
import { getBgColorFromDate } from "../../helpers/getBgColorFromDate";
import { formatDate } from "../../helpers/formatDate";

const STATUSES = ["To Do", "In Progress", "Complete"];

const MyAssignedJobsTable = ({ employeeName, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Client",
                accessor: "client",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.row.original.isNew === true ? (
                            <span>
                                <NewBadge label="New" /> {props.value}
                            </span>
                        ) : (
                            props.value
                        )}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "service",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Name",
                accessor: "name",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Year",
                accessor: "serviceYear",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Assigned",
                accessor: "assignedDate",
                /* accessor: (row) =>
                    new Date(row.assignedDate).toDateString().substring(4), */
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Due",
                accessor: "dueDate",
                /* accessor: (row) => new Date(row.dueDate).toDateString().substring(4), */
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white mb-2"
                        style={{
                            background: `${getBgColorFromDate(props.value)}`,
                        }}
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Status",
                accessor: "jobStatus",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.subscriptionId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        <span
                            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white ${
                                STATUSES[props.value] === "To Do"
                                    ? "bg-red-500"
                                    : STATUSES[props.value] == "In Progress"
                                    ? "bg-yellow-500"
                                    : "bg-teal-500"
                            }`}
                        >
                            {STATUSES[props.value]}
                        </span>
                    </a>
                ),
            },
        ],
        [employeeName]
    );
    return (
        <div>
            <div>
                {data != null ? (
                    <FirmFlowTable
                        columns={columns}
                        data={data}
                        employee={employeeName}
                    />
                ) : (
                    "No data found"
                )}
            </div>
        </div>
    );
};

export default MyAssignedJobsTable;
