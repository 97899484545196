import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { getTimestamp } from "../../../helpers/getTimestamp";
import { updateClientServiceStatus } from "../../../services/clientService/updateClientServiceStatus";
import { getFirstJobName } from "../../../services/clientServiceSubscriptionTask/getFirstJobName";
import { getEmployees } from "../../../services/getEmployees";
import ButtonAddTimestamp from "../../../utils/ButtonAddTimestamp";
import AssignedByListBox from "./AssignedByListBox";
import ButtonModal from "./ButtonModal";
import InputDateModal from "./InputDateModal";
import InputTextAreaModal from "./InputTextAreaModal";

const AssignEmployeToTaskWhenServiceStarts = ({
    open,
    setOpen,
    loggedInEmployee,
    clientSubscriptionId,
    newClientService,
}) => {
    const [displayNotes, setDisplayNotes] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [firstJob, setFirstJob] = useState({
        assignedEmployees: [],
        notes: "",
        dueDate: new Date().toISOString().split("T")[0],
    });
    let completeButtonRef = useRef(null);
    const [firstJobName, setFirstJobName] = useState("");

    const toggleNotes = () => {
        setDisplayNotes(!displayNotes);
    };

    const getNewEmployees = async () => {
        const response = await getEmployees();
        console.log(response.data.employees);
        setEmployees(response.data.employees);
    };

    const getFirstJobNameFunction = async () => {
        const response = await getFirstJobName(clientSubscriptionId);
        console.log(response.data);
        setFirstJobName(response.data);
    };

    const onHandleUpdateServiceStatus = async () => {
        const response = await updateClientServiceStatus(
            clientSubscriptionId,
            1,
            firstJob,
            loggedInEmployee
        );
        console.log(response);
        if (response.success) {
            toast.success(response.data.message);
            setOpen(false);
            newClientService();
        } else {
            toast.error(response.data.message);
        }
    };

    const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
        console.log(firstJob);
        setFirstJob({
            ...firstJob,
            assignedEmployees: [newAssignedEmployees],
        });
    };

    const onHandleClickAddTimestamp = (fieldName) => {
        setFirstJob({
            ...firstJob,
            [fieldName]:
                (firstJob[fieldName] !== null ? firstJob[fieldName] : "") +
                getTimestamp(firstJob.notes, loggedInEmployee),
        });
    };

    const onHandleChange = (e) => {
        console.log(e.target.name, e.target.value);
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        console.log(fieldName, fieldValue);
        setFirstJob({ ...firstJob, [fieldName]: fieldValue });
    };
    const onHandleCancel = () => {
        setOpen(false);
    };
    const onHandleSubmit = (e) => {
        e.preventDefault();

        if (firstJob.assignedEmployees.length < 1 || firstJob.dueDate === null) {
            toast.error("Select at least one employee and choose a due date please");
            return;
        }
        onHandleUpdateServiceStatus();
    };

    const validateAssignedEmployee = (assignedEmployees) => {
        if (Array.isArray(assignedEmployees)) {
            if (assignedEmployees.length > 0) {
                return assignedEmployees[0];
            } else {
                return null;
            }
        } else if (assignedEmployees != null) {
            return assignedEmployees;
        } else {
            return null;
        }
    };

    useEffect(() => {
        getNewEmployees();
        console.log(loggedInEmployee.employeeId);
    }, []);

    useEffect(() => {
        getFirstJobNameFunction();
    }, [clientSubscriptionId]);

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => setOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                                    Assign Employee to {firstJobName}
                                </Dialog.Title>

                                <form onSubmit={(e) => onHandleSubmit(e)}>
                                    {/*  <AssignedEmployeesListBox
                                        title="Employees *"
                                        employees={employees}
                                        assignedEmployees={
                                            firstJob.assignedEmployees
                                        }
                                        onChange={onHandleChangeAssignedEmployees}
                                    /> */}

                                    <AssignedByListBox
                                        label="Employee *"
                                        employees={employees}
                                        assignedBy={validateAssignedEmployee(
                                            firstJob.assignedEmployees
                                        )}
                                        onChange={onHandleChangeAssignedEmployees}
                                    />

                                    {firstJob.assignedEmployees.length > 0 && (
                                        <InputDateModal
                                            label={"Due Date"}
                                            name="dueDate"
                                            onChange={onHandleChange}
                                            value={firstJob.dueDate}
                                        />
                                    )}

                                    {!displayNotes ? (
                                        <button
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-base mt-4"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Add notes
                                        </button>
                                    ) : (
                                        <button
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-base mt-4"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Hide notes
                                        </button>
                                    )}

                                    {displayNotes && (
                                        <>
                                            <InputTextAreaModal
                                                label="Notes"
                                                name="notes"
                                                onChange={onHandleChange}
                                                value={firstJob.notes}
                                            />
                                            <ButtonAddTimestamp
                                                onHandleClick={() =>
                                                    onHandleClickAddTimestamp(
                                                        "notes"
                                                    )
                                                }
                                            />
                                        </>
                                    )}

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default AssignEmployeToTaskWhenServiceStarts;
