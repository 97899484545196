import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary
import NewBadge from "../../../../components/NewBadge";
import { formatDate } from "../../../../helpers/formatDate";

const ChecklistAccountabilityTable = ({ employeeName, data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Department",
                accessor: "department",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.row.original.isNew === true ? (
                            <span>
                                <NewBadge label="New" /> {props.value}
                            </span>
                        ) : (
                            props.value
                        )}
                    </a>
                ),
            },
            {
                Header: "Client",
                accessor: "client",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "service",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Job",
                accessor: "job",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Checklist Item",
                accessor: "checklistItem",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Assigend to",
                accessor: "assignedTo",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value != null
                            ? props.value.firstName + " " + props.value.lastName
                            : "Not Assigned"}
                    </a>
                ),
            },

            {
                Header: "Assigned Date",
                accessor: "assignedDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value
                            ? formatDate(props.value, {
                                  dateStyle: "short",
                              })
                            : "Not Assigned"}
                    </a>
                ),
            },
            {
                Header: "Due Date",
                accessor: "dueDate",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value
                            ? formatDate(props.value, {
                                  dateStyle: "short",
                              })
                            : "Not Assigned"}
                    </a>
                ),
            },
        ],
        [employeeName]
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default ChecklistAccountabilityTable;
