import { parseISO, isValid } from "date-fns";

export const formatDate = (date, options = {}) => {
    let validDate;

    // Si es un string, intentamos convertirlo a Date
    if (typeof date === "string") {
        validDate = parseISO(date); // Intenta parsear como ISO
    } else if (date instanceof Date) {
        validDate = date;
    } else {
        throw new Error(
            "El parámetro 'date' debe ser un string o una instancia de Date."
        );
    }

    // Validar que sea una fecha válida
    if (!isValid(validDate)) {
        throw new Error("The parameter 'date' is not a valid date.");
    }

    // Opciones predeterminadas para formatear la fecha
    const defaultOptions = {
        dateStyle: "medium",
        timeStyle: undefined,
    };

    const formatOptions = { ...defaultOptions, ...options };

    return new Intl.DateTimeFormat(navigator.language, formatOptions).format(
        validDate
    );
};
