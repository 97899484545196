/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { getTimestamp } from "../../../helpers/getTimestamp";
import { getEmployees } from "../../../services/getEmployees";
import ButtonAddTimestamp from "../../../utils/ButtonAddTimestamp";
import AssignedByListBox from "./AssignedByListBox";
import ButtonModal from "./ButtonModal";
import ErrorMessage from "./ErrorMessage";
import InputDateModal from "./InputDateModal";
import InputTextAreaModal from "./InputTextAreaModal";
import { updateTaskService } from "../../../services/clientServiceSubscriptionTask/updateTaskService";

const StartJobModal = ({
    open,
    setOpen,
    nextJob,
    setNextjob,
    loggedInEmployee,
    initializeJobs,
    getNewJobs,
}) => {
    const [displayNotes, setDisplayNotes] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [errors, setErrors] = useState([]);

    let completeButtonRef = useRef(null);

    useEffect(() => {
        console.log("nextJob", nextJob);
    }, []);

    const toggleNotes = () => {
        setDisplayNotes(!displayNotes);
    };

    const onHandleCancel = () => {
        setErrors([]);
        initializeJobs();
        setOpen(false);
    };

    const onHandleChangeAssignedEmployees = (newAssignedEmployees) => {
        console.log(nextJob);
        setNextjob({
            ...nextJob,
            assignedEmployees: [newAssignedEmployees],
            assignedDate: moment().format("YYYY-MM-DD"),
        });
    };

    const onHandleChange = (e) => {
        console.log(e.target.name, e.target.value);
        const fieldName = e.target.name;
        const fieldValue = e.target.value;
        setNextjob({ ...nextJob, [fieldName]: fieldValue });
    };

    const onHandleClickAddTimestamp = (fieldName) => {
        setNextjob({
            ...nextJob,
            [fieldName]:
                (nextJob[fieldName] !== null ? nextJob[fieldName] : "") +
                getTimestamp(nextJob.notes, loggedInEmployee),
        });
    };

    const validateForm = (form) => {
        if (form.assignedEmployees.length < 1) {
            setErrors({ assignedEmployees: "This field cannot be empty." });
            return false;
        }
        if (form.dueDate === "0001-01-01T00:00:00") {
            setErrors({ dueDate: "You need to choose a due date." });
            return false;
        }
        return true;
    };

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        console.log(nextJob);
        if (!validateForm(nextJob)) {
            return;
        }
        const jobInProgress = { ...nextJob, status: 1 };
        const updateNextJobResponse = await updateTaskService(
            nextJob.id,
            jobInProgress
        );
        if (updateNextJobResponse.data.success) {
            getNewJobs();
            setOpen(false);
            setErrors([]);
        }
    };

    const getNewEmployees = async () => {
        const response = await getEmployees();
        setEmployees(response.data.employees);
    };

    const validateAssignedEmployee = (assignedEmployees) => {
        if (Array.isArray(assignedEmployees)) {
            if (assignedEmployees.length > 0) {
                return assignedEmployees[0];
            } else {
                return null;
            }
        } else if (assignedEmployees != null) {
            return assignedEmployees;
        } else {
            return null;
        }
    };

    const validateDueDate = (dueDate) => {
        if (dueDate === "0001-01-01T00:00:00") {
            setNextjob({
                ...nextJob,
                dueDate: new Date().toISOString().split("T")[0],
            });
        }
        return dueDate;
    };

    useEffect(() => {
        getNewEmployees();
    }, []);

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => setOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center p-4">
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                                <Dialog.Title className="text-center w-full text-lg leading-6 font-medium text-gray-900">
                                    Assign Employee to {nextJob.name}
                                </Dialog.Title>

                                <form onSubmit={onHandleSubmit}>
                                    <AssignedByListBox
                                        label="Employee *"
                                        employees={employees}
                                        assignedBy={validateAssignedEmployee(
                                            nextJob.assignedEmployees
                                        )}
                                        onChange={onHandleChangeAssignedEmployees}
                                    />
                                    {
                                        <ErrorMessage
                                            error={errors["assignedEmployees"]}
                                        />
                                    }

                                    {nextJob.assignedEmployees.length > 0 && (
                                        <>
                                            <InputDateModal
                                                label={"Due Date"}
                                                name="dueDate"
                                                onChange={onHandleChange}
                                                value={validateDueDate(
                                                    nextJob.dueDate
                                                )}
                                            />
                                            <ErrorMessage
                                                error={errors["dueDate"]}
                                            />
                                        </>
                                    )}

                                    {!displayNotes ? (
                                        <button
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 text-base mt-4"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Add notes
                                        </button>
                                    ) : (
                                        <button
                                            className="inline-flex items-center p-1 border border-transparent rounded-sm shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-base mt-4"
                                            onClick={toggleNotes}
                                            type="button"
                                        >
                                            Hide notes
                                        </button>
                                    )}

                                    {displayNotes && (
                                        <>
                                            <InputTextAreaModal
                                                label="Notes"
                                                name="notes"
                                                onChange={onHandleChange}
                                                value={nextJob.notes}
                                            />
                                            <ButtonAddTimestamp
                                                onHandleClick={() =>
                                                    onHandleClickAddTimestamp(
                                                        "notes"
                                                    )
                                                }
                                            />
                                        </>
                                    )}

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default StartJobModal;
