import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { intervalTypeMap } from "../../helpers/intervalTypesMap";
import ButtonModal from "./updateClientServiceSubscriptionTaskModal/ButtonModal";
import CompletedByListBox from "./updateClientServiceSubscriptionTaskModal/CompletedByListBox";

const RenewServiceAutomaticallyModal = ({
    title,
    isModalOpen,
    setIsModalOpen,
    employees,
    employee,
    setEmployee,
    dueDate,
    setDueDate,
    onHandleSubmit,
    onHandleCancel,
    clientService,
}) => {
    const cancelButtonRef = useRef(null);

    useEffect(() => {
        const newDueDate = calculateNewRenewalDate(clientService.endDate);
        setDueDate(newDueDate);
    }, []);

    const calculateNewRenewalDate = (date) => {
        const dateFormatted = date.split("T")[0];
        const regex = new RegExp(`^${clientService.intervalType}$`);
        const daysToAdd = intervalTypeMap.filter((interval) =>
            regex.test(interval.name)
        )[0].days;
        const newDate = new Date(dateFormatted);
        newDate.setDate(newDate.getDate() + daysToAdd);
        return newDate.toISOString().split("T")[0];
    };

    const onHandleChangeAssignedEmployee = (newAssignedEmployee) => {
        console.log(newAssignedEmployee);
        setEmployee(newAssignedEmployee);
    };

    const onHandleDueDate = (e) => {
        console.log(e.target.value);
        setDueDate(e.target.value);
    };

    return (
        <AnimatePresence>
            {isModalOpen && (
                <Dialog
                    initialFocus={cancelButtonRef}
                    static
                    as={motion.div}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    {/*  <div className="fixed inset-0 bg-black/30" aria-hidden="true" /> */}

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-screen overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex min-h-full items-center justify-center">
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-y-auto  shadow-xl transform transition-all h-screen w-screen">
                                <div className="text-center">
                                    <div className="w-full flex justify-between">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-left w-full text-lg leading-6 font-medium text-gray-900"
                                        >
                                            {title} {clientService.serviceName} for{" "}
                                            {clientService.clientName} for {dueDate}
                                        </Dialog.Title>
                                        <button
                                            onClick={() => setIsModalOpen(false)}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-1">
                                    <form onSubmit={onHandleSubmit}>
                                        <CompletedByListBox
                                            title="Assign next service to"
                                            employees={employees}
                                            completedBy={employee}
                                            onChange={onHandleChangeAssignedEmployee}
                                        />
                                        {employee && (
                                            <div className="flex flex-col mt-5 sm:mt-6">
                                                <h1 className="text-md font-normal">
                                                    Due Date
                                                </h1>
                                                <input
                                                    onChange={onHandleDueDate}
                                                    type="date"
                                                    id="dueDate"
                                                    name="dueDate"
                                                    className="form-select pr-1 rounded-md border border-gray-200"
                                                    value={dueDate}
                                                ></input>
                                            </div>
                                        )}

                                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                            <ButtonModal
                                                name="Save"
                                                type="submit"
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-teal-500 text-base font-medium text-white hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                            />
                                            <ButtonModal
                                                name="Cancel"
                                                type="button"
                                                onClick={onHandleCancel}
                                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default RenewServiceAutomaticallyModal;
