import React, { useEffect, useState } from "react";
import Select from "react-select";
import Header from "../../../components/Header";
import Sidebar from "../../../components/Sidebar";
import SelectYearEfileStatusReportListBox from "../../../components/analytics/SelectYearEfileStatusReportListBox";
import { getJobsByStatuses } from "../../../services/analytics/geJobsByStatuses";
import { getServicesByStatuses } from "../../../services/analytics/getServicesByStatuses";
import { getSubscriptionYears } from "../../../services/analytics/getSubscriptionYears";
import { getEmployees } from "../../../services/getEmployees";
import { getServices } from "../../../services/getServices";
import { getServiceDepartments } from "../../../services/service/getServiceDepartments";
import JobStatusReportTable from "./components/JobStatusReportTable";
import ServiceStatusReportTable from "./components/ServiceStatusReportTable";

const SERVICE_ASSIGNMENTS = [
    {
        value: "services",
        label: "Services",
        is_showing: true,
    },
    {
        value: "jobs",
        label: "Jobs",
        is_showing: false,
    },
];

const StatusReport = () => {
    const defaultId = "00000000-0000-0000-0000-000000000000";
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [clientSubcriptionByStatuses, setClientSubcriptionByStatuses] = useState(
        []
    );

    const [jobsByStatusesReport, setJobsByStatusesReport] = useState([]);
    const [currentTaxYear, setCurrentTaxYear] = useState(0);
    const [selectedYear, setSelectedYear] = useState(null);
    const [years, setYears] = useState([
        { name: currentTaxYear, year: currentTaxYear },
    ]);
    const [departments, setDepartments] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedDepartment, setSelectedDepartment] = useState({});

    const [employees, setEmployees] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedEmployee, setSelectedEmployee] = useState({ id: defaultId });

    const [services, setServices] = useState([
        {
            value: defaultId,
            label: "All",
            is_showing: true,
        },
    ]);
    const [selectedService, setSelectedService] = useState({ id: defaultId });

    const [selectedAssignment, setSelectedAssigmnet] = useState(
        SERVICE_ASSIGNMENTS[0]
    );

    useEffect(() => {
        getReportFromThisYear();
        getDifferentSubscriptionYears();
    }, [selectedEmployee, selectedDepartment, selectedService]);

    useEffect(() => {
        fetchDepartments();
    }, []);

    useEffect(() => {
        fetchEmployees();
    }, []);

    useEffect(() => {
        getNewServices();
    }, []);

    const getServiceStatusReport = async (
        year,
        employeeId = defaultId,
        departmentId = defaultId,
        serviceId = defaultId
    ) => {
        const response = await getServicesByStatuses(
            year,
            employeeId,
            departmentId,
            serviceId
        );
        console.log(response);
        if (response.success) {
            setClientSubcriptionByStatuses(
                response.data.clientSubcriptionByStatuses
            );
        } else {
            console.log(response);
        }
    };

    const getJobStatusReport = async (
        year,
        employeeId = defaultId,
        departmentId = defaultId,
        serviceId = defaultId
    ) => {
        const response = await getJobsByStatuses(
            year,
            employeeId,
            departmentId,
            serviceId
        );
        console.log(response);
        if (response.success) {
            setJobsByStatusesReport(response.data.jobs);
        } else {
            console.log(response);
        }
    };

    const fetchDepartments = async () => {
        const response = await getServiceDepartments();
        const departmentsArray = [];
        if (response.data.success) {
            response.data.departments.map((department) => {
                departmentsArray.push({
                    value: department.id,
                    label: department.name,
                    is_showing: true,
                });
            });
            setDepartments([departments[0], ...departmentsArray]);
        }
        console.log(response);
    };

    const getNewServices = async () => {
        //get service status names
        const response = await getServices();

        const servicesArray = [];
        if (response.success) {
            response.data.map((service) => {
                servicesArray.push({
                    value: service.id,
                    label: service.name,
                    is_showing: true,
                });
            });
            setServices([services[0], ...servicesArray]);
        }
    };

    async function fetchEmployees() {
        Promise.all([getEmployees()]).then((res) => {
            let data = res[0].data.employees;
            console.log(data);
            const arr = [];
            // eslint-disable-next-line
            data.map((emp, i) => {
                arr.push({
                    value: emp.employeeId,
                    label: `${emp.firstName} ${emp.lastName}`,
                    is_showing: true,
                });
                if (arr.length > 0) {
                    // setEmployee(arr[0]);
                    let tmp = [employees[0], ...arr];
                    // console.log(tmp);
                    setEmployees(tmp);
                }
            });
        });
    }

    const getReportFromThisYear = async () => {
        const currentTaxYear = new Date().getFullYear();
        setCurrentTaxYear(currentTaxYear);
        setSelectedYear({ name: currentTaxYear, year: currentTaxYear });
        if (selectedAssignment.value === "jobs") {
            await getJobStatusReport(
                currentTaxYear,
                selectedEmployee.value,
                selectedDepartment.value,
                selectedService.value
            );
        } else {
            await getServiceStatusReport(
                currentTaxYear,
                selectedEmployee.value,
                selectedDepartment.value,
                selectedService.value
            );
        }
    };

    const getDifferentSubscriptionYears = async () => {
        const response = await getSubscriptionYears();
        if (response.success) {
            let newYears = [];
            response.data.years.map((year) => {
                newYears.push({ name: year, year: year });
            });
            newYears.push({ name: "All", year: 0 });
            setYears(newYears);
        } else {
            console.log(response);
        }
    };

    const onChangeSelectedDepartment = (department) => {
        setSelectedDepartment(department);
    };

    const onChangeSelecetedEmployee = (employee) => {
        setSelectedEmployee(employee);
    };

    const onChangeSelectedService = (service) => {
        setSelectedService(service);
    };

    const onChangeSelectedAssignment = async (assignment) => {
        setSelectedAssigmnet(assignment);
        if (assignment.value === "jobs") {
            await getJobStatusReport(
                selectedYear.year,
                selectedEmployee.value,
                selectedDepartment.value,
                selectedService.value
            );
        }
        if (assignment.value === "services") {
            await getServiceStatusReport(
                selectedYear.year,
                selectedEmployee.value,
                selectedDepartment.value,
                selectedService.value
            );
        }
    };

    const onHandleChangeYear = (option) => {
        console.log(option.year);
        setSelectedYear(option);

        if (selectedAssignment.value === "jobs") {
            getJobStatusReport(
                option.year,
                selectedEmployee.value,
                selectedDepartment.value
            );
        } else {
            getServiceStatusReport(
                option.year,
                selectedEmployee.value,
                selectedDepartment.value
            );
        }
    };
    return (
        <section>
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        Status Report{" "}
                                        {selectedYear && selectedYear.name}
                                    </h1>
                                </div>
                                <div className="flex flex-col sm:flex-row sm:space-x-2 items-center">
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Assignment
                                        </h2>
                                        {SERVICE_ASSIGNMENTS.length > 0 && (
                                            <Select
                                                onChange={(e) =>
                                                    onChangeSelectedAssignment(e)
                                                }
                                                className="basic-single min-w-[180px] w-full"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={SERVICE_ASSIGNMENTS[0]}
                                                options={SERVICE_ASSIGNMENTS}
                                                name="color"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Service
                                        </h2>
                                        {services.length > 0 && (
                                            <Select
                                                onChange={(e) =>
                                                    onChangeSelectedService(e)
                                                }
                                                className="basic-single min-w-[180px] w-full"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={services[0]}
                                                options={services}
                                                name="services"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Employee
                                        </h2>
                                        {employees.length > 0 && (
                                            <Select
                                                onChange={(e) =>
                                                    onChangeSelecetedEmployee(e)
                                                }
                                                className="basic-single min-w-[180px] w-full"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={employees[0]}
                                                options={employees}
                                                name="color"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        <h2 className="text-1xl md:text-1xl text-slate-800 font-bold">
                                            Select Department
                                        </h2>
                                        {departments.length > 0 && (
                                            <Select
                                                onChange={(e) =>
                                                    onChangeSelectedDepartment(e)
                                                }
                                                className="basic-single min-w-[180px] w-full"
                                                classNamePrefix="select"
                                                isSearchable={true}
                                                defaultValue={departments[0]}
                                                options={departments}
                                                name="departments"
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-col items-start">
                                        {selectedYear !== null && (
                                            <SelectYearEfileStatusReportListBox
                                                label={"Select Year"}
                                                onChange={onHandleChangeYear}
                                                years={years}
                                                selectedYear={selectedYear}
                                            />
                                        )}
                                    </div>
                                </div>
                                {/* Right: Actions */}
                            </div>
                        </div>
                        {selectedAssignment.label === "Services" && (
                            <ServiceStatusReportTable
                                assignments={clientSubcriptionByStatuses}
                            />
                        )}
                        {selectedAssignment.label === "Jobs" && (
                            <JobStatusReportTable jobs={jobsByStatusesReport} />
                        )}
                    </main>
                </div>
            </div>
        </section>
    );
};
export default StatusReport;
