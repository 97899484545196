import { useMemo } from "react";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import NewBadge from "../../components/NewBadge";
import { getBgColorFromDate } from "../../helpers/getBgColorFromDate";
import { formatDate } from "../../helpers/formatDate";

const STATUSES = ["To Do", "In Progress", "Complete"];

const MyAssignedServicesTable = ({
    onHandleUpdateServiceStatus,
    employeeName,
    data,
}) => {
    const columns = useMemo(
        () => [
            {
                Header: "Client",
                accessor: "client",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.row.original.isNew === true ? (
                            <span>
                                <NewBadge label="New" /> {props.value}
                            </span>
                        ) : (
                            props.value
                        )}
                    </a>
                ),
            },
            {
                Header: "Service",
                accessor: "service",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Department",
                accessor: "department",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Year",
                accessor: "year",
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Assigned Date",
                accessor: "assignedDate",
                /* accessor: (row) =>
                    new Date(row.assignedDate).toDateString().substring(4), */
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="text-gray hover:text-blue-500 rounded-full"
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Due Date",
                accessor: "dueDate",
                /* accessor: (row) => new Date(row.dueDate).toDateString().substring(4), */
                Cell: (props) => (
                    <a
                        rel="noreferrer"
                        href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                        className="inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white mb-2"
                        style={{
                            background: `${getBgColorFromDate(props.value)}`,
                        }}
                    >
                        {formatDate(props.value, {
                            dateStyle: "short",
                        })}
                    </a>
                ),
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (props) => (
                    <div>
                        {
                            <a
                                rel="noreferrer"
                                href={`/clients/service/${props.row.original.serviceId}/client/${props.row.original.clientId}`}
                                className="text-gray hover:text-blue-500 rounded-full"
                            >
                                <span
                                    className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-md font-medium text-white ${
                                        STATUSES[props.value] === "To Do"
                                            ? "bg-red-500"
                                            : STATUSES[props.value] == "In Progress"
                                            ? "bg-yellow-500"
                                            : "bg-teal-500"
                                    }`}
                                >
                                    {STATUSES[props.value]}
                                </span>
                            </a>
                        }
                        {props.value == 0 && (
                            <button
                                className="ml-2 inline-flex items-center p-1.5 border border-transparent rounded-sm shadow-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                                onClick={() =>
                                    onHandleUpdateServiceStatus(
                                        props.row.original.serviceId,
                                        props.row.original.clientId
                                    )
                                }
                            >
                                Start
                            </button>
                        )}
                    </div>
                ),
            },
        ],
        [employeeName]
    );
    return (
        <div>
            {data != null ? (
                <FirmFlowTable
                    columns={columns}
                    data={data}
                    employee={employeeName}
                />
            ) : (
                "No data found"
            )}
        </div>
    );
};

export default MyAssignedServicesTable;
